import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../../components/layout/Layout';
import AboutSection from '../../../components/section/About';
import ServiceCard from '../../../components/card/Service';
import CountsSection from '../../../components/section/Counts';
import RealisationsSection from '../../../components/section/Realisations';
import TeamSection from '../../../components/section/Team';
import PartnersSection from '../../../components/section/Partners';
import TestimonialsSection from '../../../components/section/Testimonials';
import ContactSection from '../../../components/section/Contact';

// Services
const ServicesLocalPage = ({ pageContext, data }) => {

  const services = data.services.nodes.map(service => `${service.frontmatter.title.toLowerCase()}`).join(', ');

  const pageSchemas = [
    { 
      type: "organization",
      description: `${data.site.siteMetadata.title} et son équipe dédiée et qualifiée en ${services} à ${data.city.name} (${data.city.postal_code}).`,
      addressLocality: data.city.name,
      postalCode: data.city.postal_code
    }
  ];

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.services.nodes.length);
  const headerBackgroundImage = data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Services à ${data.city.name} | ${data.site.siteMetadata.title} - Un cercle de qualité à ${data.city.name}`}
      pageDescription={`${data.site.siteMetadata.title} et son équipe dédiée et qualifiée en ${services} à ${data.city.name} (${data.city.postal_code}).`}
      pageKeywords={`moussé gava, ${data.city.name}, ${data.city.postal_code}, rénovation, construction, ${services}`}
      pageSchemas={pageSchemas}
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Nos services à {data.city.name}</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Nos services à {data.city.name}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <AboutSection site={data.site} city={data.city} sectionStyle="3" />
      {data.services && data.services.nodes.length ?
        <div id="services" className="wpo-service-area section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <h2>Nos services à {data.city.name}</h2>
                <span>Que votre projet à {data.city.name} soit personnel ou professionnel notre équipe expérimentée et dynamique mettra tout en œuvre afin de l'accomplir dans les meilleurs conditions et délais.</span>
              </div>
            </div>
            <div className="row align-items-top">
              {data.services.nodes.map(service => (
                <ServiceCard key={service.id} data={service} city={data.city} cardStyle="2" cardClassName="col-lg-4 col-md-6 col-12 mb-4" />
              ))}
            </div>
          </div>
        </div>
      : null}
      <RealisationsSection site={data.site} city={data.city} sectionStyle="1" />
      <CountsSection site={data.site} city={data.city} sectionStyle="1"/>
      <TeamSection site={data.site} city={data.city} sectionStyle="3" />
      <PartnersSection site={data.site} city={data.city} sectionStyle="1" />
      <TestimonialsSection site={data.site} city={data.city} sectionStyle="2" />
      <ContactSection site={data.site} city={data.city} sectionStyle="5" />
    </Layout>
  )
}

ServicesLocalPage.propTypes = {
  pageContext: PropTypes.shape({
    city_name: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
      city_name: PropTypes.string.isRequired,
    }).isRequired
  }),
}

export const query = graphql`
  query ($city_name: String) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    city : citiesCsv(name: {eq: $city_name}) {
      name
      name_capital
      name_lowercase
      department
      postal_code
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/local/services/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          title
          description
          image {
            icon {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default ServicesLocalPage