import * as React from 'react';

// Components
import ContactForm from '../../components/form/Contact';

const ContactSection = ({ site, city, sectionStyle }) => {

  return (
    <>
      {sectionStyle === "1" &&
        <div id="contact" className="wpo-contact-area section-padding">
          <div className="container">
            <div className="wpo-contact-default">
              <div className="wpo-contact-title">
                <h2>Un projet en tête ? Discutons</h2>
                <p>Contactez-nous pour vos projets de plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade ou isolation thermique par l'intérieure à Roanne.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      }
      {sectionStyle === "2" &&
        <div id="contact" className="wpo-contact-area section-padding">
          <div className="container">
            <div className="wpo-contact-default">
              <div className="wpo-contact-title">
                <h2>Vous souhaitez écrire un article sur {site.siteMetadata.title} ?</h2>
                <p>Contactez-nous si vous avez besoin de plus d'informations.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      }
      {sectionStyle === "3" &&
        <div id="contact" className="wpo-contact-area section-padding">
          <div className="container">
            <div className="wpo-contact-service">
              <div className="wpo-contact-title">
                <h2>Un projet en tête ? Discutons</h2>
                <p>Contactez-nous si vous avez besoin de ce service pour votre projet dans la région Roannaise.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      }
      {sectionStyle === "4" &&
        <div id="contact" className="wpo-contact-area section-padding">
          <div className="container">
            <div className="wpo-contact-project">
              <div className="wpo-contact-title">
                <h2>Un projet en tête ? Discutons</h2>
                <p>Contactez-nous pour vos projets de plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade ou isolation thermique par l'intérieure à Roanne.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      }
      {sectionStyle === "5" &&
        <div id="contact" className="wpo-contact-area section-padding">
          <div className="container">
            <div className="wpo-contact-project">
              <div className="wpo-contact-title">
                <h2>Un projet à {city.name} ? Discutons</h2>
                <p>Contactez-nous pour vos projets de plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade ou isolation thermique par l'intérieure à {city.name}.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ContactSection